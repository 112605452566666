<template>
  <b-row v-if="client" >
    <b-col cols="12">
      <div class="d-flex justify-content-between align-items-center pt-50 mb-2">
        <div>
          <h2 class="font-weight-bolder m-0" >
            <span @click="$router.go(-1)" >
              <b-button
                  variant="white"
                  class="btn-icon rounded-circle mr-50 text-danger bg-white"
                  size="sm"
              >
                <feather-icon icon="ChevronLeftIcon" />
              </b-button>
            </span> {{ client.name }}
          </h2>
        </div>
        <div class="d-flex align-items-center" >
          <div>
            <b-link
                :to="{ name: 'createProject', params: {client_param: client.id} }"
            >
              <b-button
                variant="primary"
                size="sm"
                class="text-nowrap"
              >
                {{ 'Añadir nueva obra' }}
              </b-button>
            </b-link>
          </div>
        </div>
      </div>
      <b-card no-body>
        <b-table
          hover
          responsive
          :no-local-sorting="true"
          :items="items"
          :fields="columns"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          class="table-white"
        >
          <template #cell(client)="data">
            <span v-if="data.item.client" >
              {{ data.item.client.name }}
            </span>
          </template>
          <template #cell(name)="data">
            <p class="m-0" >{{ data.item.name }}</p>
            <span v-if="data.item.SKU" class="font-small" >{{ data.item.SKU }}</span>
          </template>
          <template #cell(status)="data">
            <b-badge v-if="statusProjectsVariant[data.item.status]" :variant="statusProjectsVariant[data.item.status]">
              {{ statusProjectsText[data.item.status] }}
            </b-badge>
          </template>
          <template #cell(tasks)="data">
            <strong>{{ data.item.total_tasks_complete }}</strong> / {{ data.item.total_tasks }}
          </template>
          <template #cell(actions)="data">
            <span>
              <b-link
                :to="{ name: 'viewProject', params: { id: data.item.id} }"
                class="d-inline-block px-50 text-dark"
              >
                <feather-icon icon="EyeIcon" />
              </b-link>
              <b-link
                  :to="{ name: 'editProject', params: { id: data.item.id} }"
                  class="d-inline-block px-50 text-dark"
              >
                <feather-icon icon="Edit2Icon" />
              </b-link>
              <span
                  class="d-inline-block px-50 text-danger"
                  @click="deleteProject( data.item.id, data.item.name)"
              >
                <feather-icon icon="TrashIcon" />
              </span>
            </span>
          </template>
        </b-table>
        <div class="d-flex align-items-center justify-content-between mb-1 px-2">
          <div class="d-flex align-items-center mb-0">
            <span class="text-nowrap ">
              {{ 'Por página' }}:
            </span>
            <b-form-select
              v-model="pageLength"
              :options="pages"
              class="ml-50 mr-1"
              @input="handlePageChange"
            />
            <span class="text-nowrap"> {{ 'Total' }}: {{ totalItems }}</span>
          </div>
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @change="handleChangePage"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BBadge, BRow, BCol, BCard, BContainer, BLink, BButton, BTable, BPagination, BFormSelect,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { FlatPickrToTimestamp, TimestampToFlatPickr } from '@/libs/helpers'
import { config } from '@/shared/app.config'

export default {
  components: {
    BButton,
    BBadge,
    BLink,
    BRow,
    BCol,
    BCard,
    BContainer,
    BTable,
    BPagination,
    BFormSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      statusProjectsVariant: config.statusProjectsVariant,
      statusProjectsText: config.statusProjectsText,
      currentPage: 1,
      pageLength: 10,
      dir: false,
      pages: ['10', '15', '25'],
      sortBy: '',
      sortDesc: '',
      columns: [
        {
          label: 'Nombre',
          key: 'name',
          sortable: true,
        },
        {
          label: 'Tareas completadas',
          key: 'tasks',
          sortable: true,
        },
        {
          label: 'Estado',
          key: 'status',
        },
        {
          label: 'Imputadas',
          key: 'total_hours',
          sortable: true,
        },
        {
          label: 'Acciones',
          key: 'actions',
          class: 'column-actions',
        },
      ],
    }
  },
  watch: {
    sortBy() {
      this.currentPage = 1
      this.chargeData()
    },
    sortDesc() {
      this.currentPage = 1
      this.chargeData()
    },
  },
  computed: {
    ...mapGetters({
      client: 'clients/getClient',
      items: 'projects/getItems',
      totalItems: 'projects/getTotalItems',
    }),
  },
  methods: {
    ...mapActions({
      getClient: 'clients/getClient',
      list: 'projects/getListProjects',
      delete: 'projects/delete',
    }),
    TimestampToFlatPickr(f) {
      return TimestampToFlatPickr(f)
    },
    handleChangePage(page) {
      this.currentPage = page
      this.chargeData()
    },
    handlePageChange() {
      this.currentPage = 1
      this.chargeData()
    },
    chargeData() {
      let orderBy = ''

      if (this.sortBy) {
        const type = (this.sortDesc) ? 'desc' : 'asc'
        orderBy = {
          column: this.sortBy,
          type,
        }
      }

      this.list({
        client: this.$route.params.id,
        page: this.currentPage,
        per_page: this.pageLength,
        orderBy,
      })
    },
    deleteProject(id, name) {
      this.$bvModal
        .msgBoxConfirm(`¿Estás seguro de eliminar ${name}?`, {
          bodyClass: 'bodyDelete',
          footerClass: 'footerDelete',
          okVariant: 'danger',
          okTitle: 'Eliminar',
          cancelTitle: 'Cancelar',
          cancelVariant: 'outline-danger',
          centered: true,
        })
        .then(value => {
          if (value === true) {
            this.delete(id)
          }
        })
    },
  },
  async created() {
    await this.getClient(this.$route.params.id)
    await this.chargeData()
  },
}
</script>
